import React from 'react'

const WideMesh = () => (
  <svg
    width="100%"
    height="72"
    // viewBox="0 0 1440 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <pattern
        id="wide-mesh-bg"
        patternUnits="userSpaceOnUse"
        width="17"
        height="72"
      >
        <g opacity="0.72">
          <path
            d="M6 11.5C6.55151 11.5 7 11.0515 7 10.5C7 9.94847 6.55151 9.5 6 9.5C5.44849 9.5 5 9.94847 5 10.5C5 11.0515 5.44849 11.5 6 11.5Z"
            fill="#70798C"
          />
          <path
            d="M5.99998 28.5C6.5515 28.5 7 28.0515 7 27.5C7 26.9485 6.5515 26.5 5.99998 26.5C5.44846 26.5 5 26.9485 5 27.5C5 28.0515 5.44846 28.5 5.99998 28.5Z"
            fill="#70798C"
          />
          <path
            d="M6 45.5C6.55152 45.5 7 45.0515 7 44.5C7 43.9485 6.55152 43.5 6 43.5C5.44849 43.5 5 43.9485 5 44.5C5 45.0515 5.44849 45.5 6 45.5Z"
            fill="#70798C"
          />
          <path
            d="M6 62.5C6.55151 62.5 7 62.0515 7 61.5C7 60.9485 6.55151 60.5 6 60.5C5.44849 60.5 5 60.9485 5 61.5C5 62.0515 5.44849 62.5 6 62.5Z"
            fill="#70798C"
          />
        </g>
      </pattern>
    </defs>

    <rect width="100%" height="72" fill="url(#wide-mesh-bg)" />
  </svg>
)

export default WideMesh
