import plugin from 'tailwindcss/plugin'

const colors = {
  mainC: '#70798C',
  steel: '#607179',
  tiff: '#97B9BA',
  grass: '#A2AC86',
  chain: '#71706B',
  bard: '#ECD2D3',
  mainCSoft: '#B9D5E1',
  steelSoft: '#8B9696',
  tiffSoft: '#B2D1D2',
  grassSoft: '#D4D5A9',
  chainSoft: '#D2D4C8',
  barbSoft: '#E2DEDF',

  textDark: '#000000',
  textLight: '#ffffff',
  textLightSoft: 'rgba(255, 255, 255, 0.80)',

  bgLight: '#ffffff',
  bgDark: '#171717',

  hpBoxDark1: 'rgba(139, 150, 150, 0.60)',
  hpBoxDark2: '#606060',
  hpBoxDark3: 'rgba(110, 121, 142, 0.60)',

  stripeBgDark: '#6E798E99',

  dotDark: '#424955', // color of dots (value icons, cipher) in dark mode
}

export default {
  content: [
    `./src/pages/**/*.{js,jsx,ts,tsx}`,
    `./src/components/**/*.{js,jsx,ts,tsx}`,
  ],
  darkMode: 'class',

  theme: {
    extend: {
      fontSize: {
        xs: '13px',
        base: '17px',
        l: '1.5rem', // 24px
        xl: '2.625rem', // 42px
        '2xl': '4rem', // 64px
      },
      lineHeight: {
        tight: '1.25',
        snug: '1.3',
        normal: '1.5',
      },
      margin: {
        2: '2px',
        4: '4px',
        8: '8px',
        12: '12px',
        16: '16px',
        20: '20px',
        24: '24px',
        32: '32px',
        40: '40px',
        64: '64px',
        80: '80px',
      },
      padding: {
        2: '2px',
        4: '4px',
        6: '6px',
        8: '8px',
        10: '10px',
        12: '12px',
        16: '16px',
        20: '20px',
        24: '24px',
        32: '32px',
        40: '40px',
        64: '64px',
        80: '80px',
      },
      borderRadius: {
        lg: '24px',
      },

      fontFamily: {
        sans: ['Archia', 'serif'],
      },
      screens: {
        xxs: '340px',
        menu: '540px',
        sm: '640px',
        md: '768px',
        project: '860px',
        hpBox: '880px',
        lg: '1024px',
        xl: '1280px',
        '2xl': '1536px',
        // all breakpoints must be defined, even if they are same as default
      },
      colors: {
        ...colors,
      },
      keyframes: {
        fadeInFrames: {
          '0%': { opacity: '0' },
          '100%': { opacity: '1' },
        },

        stripeMoveFrames: {
          '0%': { transform: 'translateX(0)' },
          '100%': { transform: 'translateX(-184px)' },
        },

        burgerTopFrames: {
          '0%': { transform: 'rotate(0deg) translateY(0)' },
          '50%': { transform: 'rotate(0deg) translateY(9px)' },
          '100%': { transform: 'rotate(45deg) translateY(9px)' },
        },
        burgerTopCloseFrames: {
          '0%': { transform: 'rotate(45deg) translateY(9px)' },
          '50%': { transform: 'rotate(0deg) translateY(9px)' },
          '100%': { transform: 'rotate(0deg) translateY(0)' },
        },

        burgerMiddleFrames: {
          '0%': { opacity: 1 },
          '50%': { opacity: 1 },
          '51%': { opacity: 0 },
          '100%': { opacity: 0 },
        },
        burgerMiddleCloseFrames: {
          '0%': { opacity: 0 },
          '50%': { opacity: 0 },
          '51%': { opacity: 1 },
          '100%': { opacity: 1 },
        },

        burgerBottomFrames: {
          '0%': { transform: 'rotate(0deg) translateY(0)' },
          '50%': { transform: 'rotate(0deg) translateY(-9px)' },
          '100%': { transform: 'rotate(-45deg) translateY(-9px)' },
        },
        burgerBottomCloseFrames: {
          '0%': { transform: 'rotate(-45deg) translateY(-9px)' },
          '50%': { transform: 'rotate(0deg) translateY(-9px)' },
          '100%': { transform: 'rotate(0deg) translateY(0)' },
        },
        pinMenuFrames: {
          '0%': { position: "fixed", transform: 'translateY(-100%)' },
          '100%': { position: "fixed", transform: 'translateY(0%)' },
        },
        hideMenuFrames: {
          '0%': { position: "fixed", transform: 'translateY(0%)' },
          '100%': { position: "fixed", transform: 'translateY(-100%)' },
        },
      },
      animation: {
        stripeMove: 'stripeMoveFrames 10s linear infinite',
        fadeInFast: 'fadeInFrames .3s ease-in-out forwards',

        burgerTopOpen: 'burgerTopFrames 0.3s ease-out forwards',
        burgerMiddleOpen: 'burgerMiddleFrames 0.3s ease-out forwards',
        burgerBottomOpen: 'burgerBottomFrames 0.3s ease-out forwards',

        burgerTopClose: 'burgerTopCloseFrames 0.3s ease-out',
        burgerMiddleClose: 'burgerMiddleCloseFrames 0.3s ease-out',
        burgerBottomClose: 'burgerBottomCloseFrames 0.3s ease-out',

        pinMenu: 'pinMenuFrames 0.3s ease-out forwards',
        hideMenu: 'hideMenuFrames 0.3s ease-out',
      },
      transitionProperty: {
        bg: 'background',
        menu: 'background, transform, opacity',
      },
    },
  },
  plugins: [
    plugin(({ matchUtilities, theme }) => {
      matchUtilities(
        {
          'animation-delay': (value) => ({
            'animation-delay': value,
          }),
        },
        {
          values: theme('transitionDelay'),
        }
      )
    }),
  ],
}
