import React from 'react'
import { twMerge } from 'tailwind-merge'

const Text = ({ children, className, narrow }) => {
  let classes = 'text-base leading-normal text-textDark dark:text-textLightSoft'
  if (narrow) {
    classes += ' max-w-[510px]'
  }

  return <div className={twMerge(classes, className)}>{children}</div>
}

export default Text
