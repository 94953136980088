import React, { useState, useContext, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { useTheme } from '../utils/useTheme'

const LIGHT = 'light'
const DARK = 'dark'
const THEME = 'theme'

const isBrowser = typeof window !== 'undefined'

// default theme is also set in components/Meta.js to minimize flashes

export const ThemeContext = React.createContext({
  theme: LIGHT,
  setTheme: () => {},
  isDark: false,
})

const getInitialTheme = () => {
  if (!isBrowser) return LIGHT

  // session theme is present only when theme was changed manually
  const sessionTheme = sessionStorage.getItem(THEME)
  if (sessionTheme) return sessionTheme

  // If no session theme, check for system preference
  const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)')

  return prefersDarkScheme.matches ? DARK : LIGHT
}

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(getInitialTheme())

  const changeTheme = (newTheme) => {
    sessionStorage.setItem(THEME, newTheme)
    setTheme(newTheme)
  }

  const value = {
    theme,
    setTheme: changeTheme,
    isDark: theme === DARK,
  }

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

const ThemeSwitch = ({ className }) => {
  const { setTheme, isDark } = useContext(ThemeContext)
  const [transitionClasses, setTransitionClasses] = useState({
    icon: '',
    circle: '',
    container: '',
  })

  const onSwitch = () => setTheme(isDark ? LIGHT : DARK)

  useEffect(() => {
    if (!isBrowser) return

    if (isDark) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [isDark])

  const {
    result: {
      bgColorClasses,
      iconColor,
      lightIconClasses,
      darkIconClasses,
      circleClasses,
    },
  } = useTheme(({ isDark, colors }) => {
    return {
      bgColorClasses: isDark ? 'bg-mainC' : 'bg-mainCSoft',
      iconColor: isDark ? colors.mainCSoft : colors.mainC,
      lightIconClasses: isDark ? 'scale-1' : 'scale-75',
      darkIconClasses: isDark ? 'scale-75' : 'scale-1',
      circleClasses: isDark ? 'bg-black left-[44px]' : 'bg-white left-[4px]',
    }
  })

  useEffect(() => {
    setTimeout(() => {
      setTransitionClasses({
        icon: 'transition ease-linear',
        circle: 'transition-all ease-linear',
        container: 'transition-all',
      })
    }, 500)
  }, [])

  return (
    <div
      className={twMerge(
        bgColorClasses,
        'relative flex h-[40px] w-[80px] cursor-pointer flex-row justify-between rounded-full p-4',
        transitionClasses.container,
        className
      )}
      onClick={onSwitch}
    >
      <div
        className={twMerge(
          'absolute h-[32px] w-[32px] rounded-full',
          circleClasses,
          transitionClasses.circle
        )}
      />

      <div className={twMerge(lightIconClasses, transitionClasses.icon)}>
        <Light color={iconColor} />
      </div>
      <div className={twMerge(darkIconClasses, transitionClasses.icon)}>
        <Dark color={iconColor} />
      </div>
    </div>
  )
}

export default ThemeSwitch

const Light = ({ color }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="max-h-full max-w-full"
  >
    <circle cx="15.4377" cy="15.4375" r="7.5" fill={color} />
    <circle cx="21.4377" cy="5.04529" r="1.5" fill={color} />
    <circle cx="9.43774" cy="25.8298" r="1.5" fill={color} />
    <circle cx="5.04541" cy="9.4375" r="1.5" fill={color} />
    <circle cx="25.8301" cy="21.4376" r="1.5" fill={color} />
    <circle cx="27.4375" cy="15.4375" r="1.5" fill={color} />
    <circle cx="3.4375" cy="15.4375" r="1.5" fill={color} />
    <circle cx="15.4375" cy="3.4375" r="1.5" fill={color} />
    <circle cx="15.4375" cy="27.4375" r="1.5" fill={color} />
    <circle cx="25.8298" cy="9.43762" r="1.5" fill={color} />
    <circle cx="5.04541" cy="21.4376" r="1.5" fill={color} />
    <circle cx="9.4375" cy="5.04529" r="1.5" fill={color} />
    <circle cx="21.4375" cy="25.83" r="1.5" fill={color} />
  </svg>
)

const Dark = ({ color }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="max-h-full max-w-full"
  >
    <path
      d="M20.655 20.7354C21.1671 19.9092 20.2777 19 19.3057 19C15.6606 19 12.7057 16.0451 12.7057 12.4C12.7057 11.1085 13.0766 9.9037 13.7178 8.88623C14.1955 8.12813 13.8961 7 13 7C8.02944 7 4 11.0294 4 16C4 20.9706 8.02944 25 13 25C16.2332 25 19.0681 23.2951 20.655 20.7354Z"
      fill={color}
    />
    <circle cx="22.5" cy="14.5" r="1.5" fill={color} />
    <circle cx="26.5" cy="8.5" r="1.5" fill={color} />
    <circle cx="19.5" cy="8.5" r="1.5" fill={color} />
  </svg>
)
