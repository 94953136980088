import './src/styles/global.css'
import React from 'react'
import { ThemeProvider } from './src/components/ThemeSwitch'
import Page from './src/components/Page'

export const wrapRootElement = ({ element }) => {
  return <ThemeProvider>{element}</ThemeProvider>
}

export const wrapPageElement = ({ element, props }) => {
  return <Page {...props}>{element}</Page>
}
