import { useContext, useState, useEffect } from 'react'
import { ThemeContext } from '../components/ThemeSwitch'
import tailwindConf from '../../tailwind.config'

const colors = tailwindConf.theme.extend.colors

export const useTheme = (getResult = () => '') => {
  const { isDark } = useContext(ThemeContext)

  // use light theme as default, to ensure dark mode is hydrated correctly
  const [isDarkState, setIsDarkState] = useState(false)
  const [options, setOptions] = useState(getResult({ isDark: false, colors }))

  useEffect(() => {
    setOptions(getResult({ isDark, colors }))
    setIsDarkState(isDark)
  }, [isDark])

  return { isDark: isDarkState, result: options }
}

export default useTheme
