import React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import Text from './Text'
import { useTheme } from '../utils/useTheme'

export const routes = {
  HOME: '/',
  TEAM: '/about',
  PORTFOLIO: '/portfolio',
}

const Link = (props) => <GatsbyLink {...props} />

export const TextLink = ({ to, children }) => (
  <Link to={to} className="underline hover:no-underline">
    <Text>{children}</Text>
  </Link>
)

export const ArrowLink = ({ to, children }) => (
  <Link to={to}>
    <div className="flex flex-row">
      <Text className="mr-8 dark:text-textLight">
        <span className="link-underline">{children}</span>
      </Text>
      <Arrow />
    </div>
  </Link>
)

const Arrow = () => {
  const { result: colors } = useTheme(({ isDark, colors }) =>
    isDark
      ? {
          circle: colors.steelSoft,
          arrow: 'white',
        }
      : {
          circle: colors.steel,
          arrow: 'black',
        }
  )

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ico / arrow button">
        <path
          id="Vector"
          d="M8.2425 21C4.725 19.53 2.25 16.0575 2.25 12C2.25 6.615 6.615 2.25 12 2.25C17.385 2.25 21.75 6.615 21.75 12C21.75 17.385 17.385 21.75 12 21.75"
          stroke={colors.circle}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M15.75 12H8.25"
          stroke={colors.arrow}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M12.75 15L15.75 12L12.75 9"
          stroke={colors.arrow}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default Link
