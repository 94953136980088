import React from 'react'
import { PageContent } from './Page'
import WideMesh from './Mesh/WideMesh'
import { useTheme } from '../utils/useTheme'
import Stripe from './Stripe'

export default function Footer() {
  return (
    <>
      <div className="m-auto w-full max-w-[1920px]">
        <WideMesh />

        <PageContent className="pt-40">
          <div className="flex flex-col items-center p-24">
            <div className="flex flex-row gap-[16px]">
              <a href="mailto:mc@mitonc.com" aria-label="Email us">
                <Mail />
              </a>
              <a
                href="https://twitter.com/MitonCfund"
                target="_blank"
                rel="noreferrer"
                aria-label="Twitter"
              >
                <XIcon />
              </a>
            </div>

            <div className="mt-16 text-base text-steel dark:text-mainC">
              Mail us at <a href="mailto:mc@mitonc.com" aria-label="Email us">mc@mitonc.com</a>
            </div >
            <div className="mt-16 text-xs text-steel dark:text-mainC">
              Proud member of <a href="https://miton.cz/en/">Miton</a> family
              </div>
          </div>
        </PageContent>
      </div>

    </>
  )
}

const Mail = () => {
  const { result: color } = useTheme(({ isDark, colors }) =>
    isDark ? colors.steelSoft : colors.steel
  )

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 26H27C28.1 26 29 25.1 29 24V14"
        stroke="#8B9696"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 14V23"
        stroke="#8B9696"
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 9L16 17.5L29 9V7C29 5.9 28.1 5 27 5H5C3.9 5 3 5.9 3 7V9Z"
        stroke={color}
        strokeWidth="2.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const XIcon = () => {
  const { result: color } = useTheme(({ isDark, colors }) =>
    isDark ? colors.steelSoft : colors.mainC
  )

  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.4351 2.53857H28.9331L19.1063 13.7699L30.6667 29.0532H21.615L14.5254 19.784L6.4133 29.0532H1.91262L12.4233 17.04L1.33337 2.53857H10.6149L17.0232 11.011L24.4351 2.53857ZM22.8565 26.3609H25.3488L9.26057 5.08942H6.58599L22.8565 26.3609Z"
        fill={color}
      />
    </svg>
  )
}
