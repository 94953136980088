import React from 'react'
import { twMerge } from 'tailwind-merge'
import NavBar from './Navbar'
import Footer from './Footer'
import Helmet from 'react-helmet'
import { useTheme } from '../utils/useTheme'

const PageLayout = ({ children }) => {
  return (
    <div className="min-h-screen overflow-hidden bg-bgLight transition dark:bg-bgDark">
      <NavBar />
      {children}
    </div>
  )
}

export default function Page({ children, className }) {
  const { result: themeColor } = useTheme(({ isDark, colors }) =>
    isDark ? colors.bgDark : colors.bgLight
  )

  return (
    <>
      <PageLayout>
        <Helmet>
          <meta name="theme-color" content={themeColor} />
        </Helmet>
        <div
          className={twMerge(
            'm-auto flex w-full max-w-[1920px] flex-col items-center overflow-hidden',
            className
          )}
        >
          {children}
        </div>
        <Footer />
      </PageLayout>
    </>
  )
}
export function PageContent({ children, className, classNameOuter }) {
  return (
    <div
      className={twMerge('w-full max-w-full px-20 md:px-32', classNameOuter)}
    >
      <div
        className={twMerge(
          'mx-auto flex w-full max-w-[1024px] flex-col',
          className
        )}
      >
        {children}
      </div>
    </div>
  )
}
