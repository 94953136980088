import { useState, useEffect, useCallback } from 'react'
import useScrollDirection from './useScrollDirection'

export const usePinMenu = ({ animateOpenClass, animateCloseClass }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isPinned, setIsPinned] = useState(false)
  const [hasPinnedStyle, setHasPinnedStyle] = useState(false)
  const [menuAnimationClass, setMenuAnimationClass] = useState('')

  const { isScrollingUp, scrollDistance, scrollTop, isLongScroll } =
    useScrollDirection()

  const closeMenu = useCallback(() => setIsOpen(false), [setIsOpen])
  const toggleMenu = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  useEffect(() => {
    // remove all pinned styles if user scrolls close to the top
    if (scrollTop <= 100) {
      setMenuAnimationClass('')
      setIsPinned(false)
      setHasPinnedStyle(false)
      return
    }

    if (isPinned) {
      if ((!isScrollingUp && isLongScroll) || scrollTop <= 400) {
        setIsOpen(false)
        setIsPinned(false)
        setMenuAnimationClass(animateCloseClass)
      }
    } else {
      if (isScrollingUp && isLongScroll && scrollTop > 800) {
        setIsOpen(false)
        setHasPinnedStyle(true)
        setIsPinned(true)
        setMenuAnimationClass(animateOpenClass)
      }
    }
  }, [scrollTop, scrollDistance, isScrollingUp])

  return {
    isOpen,
    isPinned,
    hasPinnedStyle,
    menuAnimationClass,
    closeMenu,
    toggleMenu,
  }
}

export default usePinMenu
