import React, { useEffect, useState } from 'react'
import Link, { routes } from './Link'
import { twMerge } from 'tailwind-merge'
import ThemeSwitch from './ThemeSwitch'
import Text from './Text'
import { useTheme } from '../utils/useTheme'
import useScrollDirection from '../utils/useScrollDirection'
import usePinMenu from '../utils/usePinMenu'

const MenuLink = ({ to, children, onClick, className }) => {
  return (
    <div
      className={twMerge(
        className,
        'mr-0 animate-fadeInFast border-t opacity-0 menu:animate-none menu:border-none menu:opacity-100 sm:mr-20'
      )}
      onClick={onClick}
    >
      <Link to={to}>
        <Text className="px-0 py-16 hover:text-mainC dark:text-textLight dark:hover:text-mainCSoft menu:p-10">
          {children}
        </Text>
      </Link>
    </div>
  )
}

const menuConfig = [
  { to: routes.HOME, text: 'Home', className: 'animation-delay-[0ms]' },
  { to: routes.TEAM, text: 'About', className: 'animation-delay-[75ms]' },
  {
    to: routes.PORTFOLIO,
    text: 'Portfolio',
    className: 'animation-delay-[150ms]',
  },
]

const MenuItems = ({ onClose }) => (
  <>
    {menuConfig.map(({ to, text, className }) => (
      <MenuLink key={to} to={to} onClick={onClose} className={className}>
        {text}
      </MenuLink>
    ))}
  </>
)

export default function NavBar() {
  const { isOpen, hasPinnedStyle, menuAnimationClass, closeMenu, toggleMenu } =
    usePinMenu({
      animateOpenClass: 'animate-pinMenu',
      animateCloseClass: 'animate-hideMenu',
    })

  return (
    <>
      <div className="h-[82px] menu:h-[88px] md:h-[120px]" />
      <div
        className={twMerge(
          'absolute top-0 z-30 w-full bg-bgLight transition-bg dark:bg-bgDark',
          hasPinnedStyle && 'shadow-md dark:shadow-gray-700',
          menuAnimationClass
        )}
      >
        <div
          className={twMerge(
            'm-auto flex max-w-[1920px] flex-row items-center justify-between px-20 py-12 md:p-32',
            hasPinnedStyle && 'md:py-8'
          )}
        >
          <Link to="/">
            <Logo hasPinnedStyle={hasPinnedStyle} />
          </Link>

          <div className="flex-column hidden items-center menu:flex menu:flex-row">
            <MenuItems />
            <div className="pr-10" />
            <ThemeSwitch />
          </div>
          <div className="flex items-center menu:hidden">
            <ThemeSwitch className="hidden xxs:flex" />

            <button
              className="py-8 pl-16 focus:outline-none"
              onClick={toggleMenu}
            >
              <BurgerIcon isOpen={isOpen} />
            </button>
          </div>
        </div>
        {isOpen && (
          <div className="absolute z-50 w-full bg-bgLight px-20 py-12 shadow-md dark:bg-bgDark dark:shadow-gray-700 menu:hidden">
            <div className="flex flex-col items-stretch">
              <MenuItems onClose={closeMenu} />

              <div className="pb-20 pt-10 xxs:hidden">
                <ThemeSwitch className="flex" />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

const BurgerIcon = ({ isOpen }) => {
  const { result: color } = useTheme(({ isDark }) =>
    isDark ? 'white' : 'black'
  )

  const [wasOpen, setWasOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setWasOpen(true)
    }
  }, [isOpen])

  const animations = [
    {
      open: 'animate-burgerTopOpen',
      close: 'animate-burgerTopClose',
    },
    {
      open: 'animate-burgerMiddleOpen',
      close: 'animate-burgerMiddleClose',
    },
    {
      open: 'animate-burgerBottomOpen',
      close: 'animate-burgerBottomClose',
    },
  ]

  const classes = animations.map(({ open, close }) => {
    return isOpen ? open : wasOpen ? close : ''
  })

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <rect
        x="4"
        y="6"
        width="24"
        height="2"
        fill={color}
        className={twMerge('origin-center', classes[0])}
      />
      <rect
        x="4"
        y="15"
        width="24"
        height="2"
        fill={color}
        className={twMerge('', classes[1])}
      />
      <rect
        x="4"
        y="24"
        width="24"
        height="2"
        fill={color}
        className={twMerge('origin-center', classes[2])}
      />
    </svg>
  )
}

const Logo = ({ hasPinnedStyle }) => {
  const { result: color } = useTheme(({ isDark }) =>
    isDark ? 'white' : 'black'
  )

  const classes = 'w-[100px] menu:w-[120px] md:w-[145px]'

  return (
    <svg
      width="145"
      height="36"
      viewBox="0 0 145 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(
        'w-[100px] menu:w-[120px]',
        hasPinnedStyle ? 'md:w-[120px]' : 'md:w-[145px]'
      )}
    >
      <path
        d="M21.6606 8.83911C18.7685 8.83911 16.6521 10.016 15.3651 12.3342C14.1497 10.112 11.8796 8.83911 9.1054 8.83911C6.66728 8.83911 4.63314 9.91644 3.44983 11.8116V9.32267H0V29.1449H3.44983V17.2658C3.44983 14.3147 5.51257 12.0889 8.24384 12.0889C10.9751 12.0889 12.8126 13.9733 12.8126 16.7822V29.1449H16.2625V17.376C16.2625 13.7244 18.7077 12.0889 21.1315 12.0889C24.0058 12.0889 25.5895 13.9556 25.5895 17.3404V29.1484H29.0393V16.3769C29.0393 11.9431 26.0006 8.84622 21.6535 8.84622"
        fill={color}
      />
      <path
        d="M40.8938 9.32271H33.7797V12.3094H37.4404V29.1414H40.8938V9.32271ZM39.1492 1.55737C37.7908 1.55737 36.7683 2.57426 36.7683 3.92537C36.7683 5.27649 37.7908 6.32893 39.1492 6.32893C40.5077 6.32893 41.5659 5.31915 41.5659 3.92537C41.5659 2.5316 40.5256 1.55737 39.1492 1.55737Z"
        fill={color}
      />
      <path
        d="M52.7126 3.71204H49.4487V9.3227H45.6021V12.3094H49.2628V23.3956C49.2628 27.1574 51.3899 29.1449 55.4189 29.1449H58.4326V26.0836H55.3795C53.4634 26.0836 52.7162 25.3191 52.7162 23.36V12.3129H58.6935V9.32626H52.7162V3.71559L52.7126 3.71204Z"
        fill={color}
      />
      <path
        d="M71.474 26.4533C67.4415 26.4533 64.6244 23.4844 64.6244 19.232C64.6244 14.9796 67.5058 12.0107 71.474 12.0107C75.4422 12.0107 78.3594 15.0471 78.3594 19.232C78.3594 23.4169 75.4637 26.4533 71.474 26.4533ZM71.5098 8.83911C65.5718 8.83911 61.0959 13.3084 61.0959 19.232C61.0959 25.1556 65.461 29.6249 71.4705 29.6249C77.48 29.6249 81.8843 25.1556 81.8843 19.232C81.8843 16.352 80.844 13.7173 78.9493 11.8116C77.0474 9.89511 74.4055 8.83911 71.5098 8.83911Z"
        fill={color}
      />
      <path
        d="M95.462 8.83911C93.5029 8.83911 90.861 9.62844 89.5454 11.8187V9.32267H86.0956V29.1449H89.5454V17.376C89.5454 14.3609 91.7833 12.0889 94.7505 12.0889C97.5068 12.0889 99.5088 14.1262 99.5088 16.9316V29.1449H102.959V16.7431C102.959 12.0889 99.877 8.83911 95.462 8.83911Z"
        fill={color}
      />
      <path
        d="M109.251 17.9982C109.251 27.9253 117.369 36 127.351 36C135.258 36 141.994 30.9298 144.45 23.8827H140.374C138.118 28.8036 133.131 32.2311 127.351 32.2311C119.461 32.2311 113.04 25.8453 113.04 17.9982C113.04 10.1511 119.461 3.76533 127.351 3.76533C133.696 3.76533 139.084 7.89333 140.957 13.5929H144.9C142.92 5.792 135.802 0 127.351 0C117.369 0 109.254 8.07467 109.254 18.0018"
        fill={color}
      />
    </svg>
  )
}
