import { useEffect, useState } from 'react'

export const useScrollDirection = () => {
  const [scrollState, setScrollState] = useState({
    lastScrollTop: 0,
    isScrollingUp: false,
    isBelowThreshold: false,
    isBelowThresholdSafetyOne: false,
    isBelowThresholdSafetyTwo: false,
    scrollDistance: 0,
    isScrollingUpLong: false,
    isLongScroll: false,
  })

  useEffect(() => {
    function handleScroll() {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop

      setScrollState((prev) => {
        const newState = {
          ...prev,
          lastScrollTop: currentScrollTop <= 0 ? 0 : currentScrollTop,
          isScrollingUp: currentScrollTop < prev.lastScrollTop,
          scrollTop: currentScrollTop,
        }

        const wasDirectionChanged = prev.isScrollingUp !== newState.isScrollingUp
        const scrollDistance = wasDirectionChanged
          ? 0
          : prev.scrollDistance + Math.abs(currentScrollTop - prev.lastScrollTop)
        
        newState.scrollDistance = scrollDistance
        newState.isLongScroll = scrollDistance > 50

        return newState
      })
    }

    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return scrollState
}

export default useScrollDirection
